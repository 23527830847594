<template>
	<div class="h-full">
		<fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
			@onPageChange="onPageChange" :queryParam="queryParam">
			<div slot="buttonGroup">
				<div class="h-handle-button">
					<div class="h-b"><el-button type="primary" size="small" @click="exportExcel" >导出</el-button></div>
				</div>
				<!-- <div class="customer-filter-box">
					<span class="option-list">
						<el-input v-model="orderNoValue" placeholder="请输入内容" @blur="reloadPageList()"
							class="input-with-select">
							<el-select v-model="orderNoType" size="mini" placeholder="请选择" @change="reloadPageList()"
								slot="prepend">
								<el-option v-for="item in orderNoTypes" :key="item.Key" :label="item.Value"
									:value="item.Key">
								</el-option>
							</el-select>
						</el-input>
					</span>
					<span class="option-list option-list-time">
						<el-select v-model="orderTimeType" size="mini" placeholder="请选择" @change="reloadPageList()">
							<el-option v-for="item in orderTimeTypes" :key="item.Key" :label="item.Value"
								:value="item.Key">
							</el-option>
						</el-select>
					</span>
					<span class="timepicker orderTime-timepicker">
						<el-date-picker v-model="startEndTime" style="height: 24px;" size="mini" type="daterange"
							placeholder="选择日期范围" :clearable="false" @change="reloadPageList()"
							:picker-options="pickerOptions" :default-time="['00:00:00', '23:59:59']">
						</el-date-picker>
					</span>
				</div> -->
			</div>
			<p slot="elList">
				<el-table ref="stockinfoListTable" :data="dataSource.Result" border
					@selection-change="handleSelectionChange">
					<el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
						:prop="col.FieldName" :label="col.DisplayName" :render-header="bindFilter(queryParam,col)"
						:fixed="index==0&&(!config||!config.isDetailDisplay)"
						:min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
						v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
						<template slot-scope="scope">
							<span> {{ scope.row[col.FieldName] }}</span>
						</template>
					</el-table-column>
				</el-table>
			</p>
		</fixed-list>
	</div>
</template>
<script>
	import sha from '../../../../../scripts/sha.js';
	export default {
		mounted() {
			// this.Utils.lazy(() => {
			//     this.Event.$on("reloadPageList", () => this.reloadPageList());
			// });
			var _this = this;
			this.getOrderTypeList("ordernotype");
			this.getOrderTypeList("ordertimetype");
			this.initialize();
		},
		data() {
			return {
				//tableHeight: window.innerHeight-250,
				startEndTime: [new Date(), new Date()],
				multipleSelection: [],
				queryParam: {
					PageIndex: 1,
					PageSize: 10,
					PageParams: {
						OrderNoType: '',
						OrderNoValue: '',
						OrderTimeType: '',
						OrderTimeStart: '',
						OrderTimeEnd: ''

					}


				},
				//今天、昨天、过去7天、过去30天、过去90天
				pickerOptions: {
					shortcuts: [{
						text: '今天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24);
							picker.$emit('pick', [end, end]);
						}
					}, {
						text: '昨天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							end.setTime(end.getTime() - 3600 * 1000 * 24);
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 2);
							picker.$emit('pick', [end, end]);
						}
					}, {
						text: '过去7天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 6);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '过去30天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 29);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '过去90天',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 89);
							picker.$emit('pick', [start, end]);
						}
					}],
					disabledDate(time) {
						return time.getTime() < Date.now() - 3600 * 1000 * 24 * 365;
					},
					onPick(date) {
						if (date.maxDate != null && date.minDate != null) {

						}
					}
				},
				dataSource: {
					ColDefs: {},
					Result: [],
					TotalCount: 0
				},
				orderNoType: '1',
				orderNoTypes: [],
				orderNoValue: '',
				orderTimeType: '1',
				orderTimeTypes: []
			}
		},
		props: {
			config: {
				isDetailDisplay: false
			},
			option: {}
		},
		methods: {
			reloadPageList: function() {
				this.initialize();
			},
			initialize() {
				this.onPageChange(this.queryParam);
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.$emit("onCheckRow", this.multipleSelection);
			},
			onPageChange(param) {
				var _this = this;
				if (this.startEndTime.length > 0) {
					param.PageParams.OrderNoType = this.orderNoType;
					param.PageParams.OrderNoValue = this.orderNoValue;
					param.PageParams.OrderTimeType = this.orderTimeType;
					param.PageParams.OrderTimeStart = this.formatDate(this.startEndTime[0]);
					param.PageParams.OrderTimeEnd = this.formatDate(this.startEndTime[1]);
				}
				this.queryParam = param;
				this.$ajax.query("omsapi/stockbatchinfo/searchstocklockinfo", "post", param, data => {
					_this.dataSource = data;
				});
			},
			onDataSourceChange(ds) {
				var _this = this;

				_this.dataSource = {
					ColDefs: {
						BodyFieldParams: []
					},
					Result: [],
					TotalCount: 0
				};
				_this.$nextTick(function() {
					_this.dataSource = ds;

				});
			},
			exportStockData() {
				if (this.multipleSelection == null || this.multipleSelection.length == 0) {
					this.Utils.messageBox(`没有需要导出的数据.`, "error");
					return false;
				}
			},
			exportExcel: function() {
			/* 	if (this.multipleSelection == null || this.multipleSelection.length == 0) {
					this.Utils.messageBox(`没有需要导出的数据.`, "error");
					return false;
				} */
				var _this = this;
				_this.queryParam.PageParams.OrderNoType =this.orderNoType;
				_this.queryParam.PageParams.OrderNoValue = this.orderNoValue;
				_this.queryParam.PageParams.OrderTimeType =  this.orderTimeType;
				if (this.startEndTime.length > 0) {
					_this.queryParam.PageParams.OrderTimeStart =  this.formatDate(this.startEndTime[0]);
					_this.queryParam.PageParams.OrderTimeEnd =this.formatDate(this.startEndTime[1]);
				}
				var str = encodeURIComponent(JSON.stringify(_this.queryParam));
				var urlFile = "omsapi/stockbatchinfo/exportstocklockinfo?queryParam=" + str + "&token=" + sha
					.getToken();
				_this.Utils.export(urlFile);
			},
			formatDate(date) {
				var y = date.getFullYear();
				var m = date.getMonth() + 1;
				m = m < 10 ? ('0' + m) : m;
				var d = date.getDate();
				d = d < 10 ? ('0' + d) : d;
				var h = date.getHours();
				var minute = date.getMinutes();
				minute = minute < 10 ? ('0' + minute) : minute;
				var second = date.getSeconds();
				second = minute < 10 ? ('0' + second) : second;
				return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
			},
			//获取物流类型
			getOrderTypeList: function(type) {
				var _this = this;
				this.$ajax.query("omsapi/stockbatchinfo/getordertypelist", "get", {
					type: type
				}, data => {
					if (data.IsSuccess == true) {
						if (type == "ordernotype") {
							_this.orderNoTypes = data.Result;
							_this.orderNoType = data.Result[0].Key;
						} else {
							_this.orderTimeTypes = data.Result;
							_this.orderTimeType = data.Result[0].Key;
						}
					}
				});
			},
		},
		components: {}
	}
</script>
<style>
	.customer-filter-box {
		clear: both;
	}

	/* .customer-filter-box .option-list .el-input__inner{
        width: 115px;
    } */
	.input-with-select .el-input--suffix .el-input__inner {
		width: 80px;
	}

	.customer-filter-box .el-select .el-input {
		width: 104px;
	}

	.customer-filter-box .option-list-time {
		margin-left: 18px;
	}

	.customer-filter-box .option-list-time .el-select .el-input {
		width: 166px;
	}

	.customer-filter-box .orderTime-timepicker .el-input__inner {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.customer-filter-box .el-input-group__prepend {
		border-color: #3c8ad9;
		background-color: #ffffff;
		color: #333333;
	}

	.customer-filter-box .timepicker .el-input__inner .el-range-input {
		width: 128px;
	}
</style>